.mat-mdc-form-field-type-mat-select {
  .mat-mdc-floating-label {
    overflow: visible;

    mat-label {
      position: relative;
      top: -2px;
      color: #484848;
      font-size: 0.9em;
    }

    &.mdc-floating-label--float-above {
      mat-label {
        top: -10px;
        left: 0px;
      }
    }
  }

  .mat-mdc-select {
    padding: 0 2px;
  }

  label:has(~ mat-select.mat-mdc-select-required) {
    mat-label::after {
      content: "*";
      color: $dark-red;
      margin-left: 1px;
      font-weight: 700;
      vertical-align: top;
    }
  }
}

.multi-select-options-panel {
}
